import React from "react";
import BoilerPlateGraph from "./BoilerPlateGraph";
import dataEdge from "../data/data_edge.json";
import dataNode from "../data/data_node.json";

function getNeigbourNodes(CTH) {
  // loop through the edges and find the nodes that are connected to the CTH#
  let nodes = [];
  let edges = [];
  for (let i = 0; i < dataEdge.length; i++) {
    if (dataEdge[i]["CTH#"] == CTH) {
      nodes.push(dataEdge[i].source, dataEdge[i].target);
      edges.push(dataEdge[i]);
    }
  }
  // loop through the dataNodes and find nodes that are in nodes array
  let nodesData = [];
  for (let i = 0; i < dataNode.length; i++) {
    if (nodes.includes(dataNode[i].Id)) {
      nodesData.push({
        id: dataNode[i].Id,
        label: dataNode[i].Id,
        group: dataNode[i].shape,
      });
    }
  }
  return { nodes: nodesData, links: edges };
}

export default function CTHSearch() {
  return (
    <>
      <BoilerPlateGraph
        getDataFunction={getNeigbourNodes}
        title="Search by CTH"
        inputText="Enter CTH"
      />
    </>
  );
}
