import React from "react";
import { ForceGraph2D } from "react-force-graph";

import SpriteText from "three-spritetext";

export default function Graph2D({ graphData, onNodeClick }) {
  const fgRef = React.useRef();

  return (
    <>
      <ForceGraph2D
        ref={fgRef}
        d3VelocityDecay={0.5}
        onNodeDragEnd={(node) => {
          node.fx = node.x;
          node.fy = node.y;
          node.fz = node.z;
        }}
        linkLabel={(link) => link.tablet}
        linkAutoColorBy={(link) => link.tablet}
        linkWidth={(link) => 1}
        onNodeClick={(node) => {
          //copy id to clipboard
          navigator.clipboard.writeText(node.id);
        }}
        cooldownTicks={100}
        d3AlphaDecay={0.1}
        width={window.innerWidth * 0.8}
        graphData={graphData}
        nodeAutoColorBy="group"
        nodeLabel="group"
        nodeThreeObject={(node) => {
          const sprite = new SpriteText(node.id);
          sprite.color = node.color;
          sprite.textHeight = 8;
          return sprite;
        }}
        nodeCanvasObject={(node, ctx, globalScale) => {
          const label = node.id;
          const fontSize = 18 / globalScale;
          ctx.font = `${fontSize}px Sans-Serif`;
          const textWidth = ctx.measureText(label).width;
          const bckgDimensions = [textWidth, fontSize].map(
            (n) => n + fontSize * 0.2
          ); // some padding

          ctx.fillStyle = "rgba(255, 255, 255, 0.8)";
          ctx.fillRect(
            node.x - bckgDimensions[0] / 2,
            node.y - bckgDimensions[1] / 2,
            ...bckgDimensions
          );

          ctx.textAlign = "center";
          ctx.textBaseline = "middle";
          ctx.fillStyle = node.color;
          ctx.fillText(label, node.x, node.y);

          node.__bckgDimensions = bckgDimensions; // to re-use in nodePointerAreaPaint
        }}
        nodePointerAreaPaint={(node, color, ctx) => {
          ctx.fillStyle = color;
          const bckgDimensions = node.__bckgDimensions;
          bckgDimensions &&
            ctx.fillRect(
              node.x - bckgDimensions[0] / 2,
              node.y - bckgDimensions[1] / 2,
              ...bckgDimensions
            );
        }}
      />
    </>
  );
}
