import { Page, Main, PageContent, Nav } from "grommet";

import { BrowserRouter, Routes, Route, Link } from "react-router-dom";
import NavBar from "./components/NavBar";
import Home from "./components/Home";
import NameAndNeighbour from "./components/NameAndNeighbour";
import CTHSearch from "./components/CTHSearch";
import NameTree from "./components/NameTree";
import CTHTree from "./components/CTHTree";
import CTHTable from "./components/CTHTable";
import NameSearch from "./components/NameSearch";

function App() {
  return (
    <Main>
      <Page>
        <BrowserRouter>
          <PageContent>
            <NavBar />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/name" element={<NameAndNeighbour />} />
              <Route path="/cth" element={<CTHSearch />} />
              <Route path="/nameTree" element={<NameTree />} />
              <Route path="/cthTree" element={<CTHTree />} />
              <Route path="/cthTable" element={<CTHTable />} />
              <Route path="/namesearch" element={<NameSearch />} />
            </Routes>
          </PageContent>
        </BrowserRouter>
      </Page>
    </Main>
  );
}

export default App;
