import React from "react";
import { Box, Nav, Anchor } from "grommet";
import { Link } from "react-router-dom";

export default function NavBar() {
  return (
    <>
      <Nav background="brand" pad="small" direction="row">
        <Anchor as={Link} to="/" label="Home" />
        <Anchor as={Link} to="/cth" label="Search by CTH" />
        <Anchor as={Link} to="/name" label="Search by Name" />
        <Anchor as={Link} to="/nameTree" label="Get Tree by Name" />
        <Anchor as={Link} to="/cthTree" label="Get CTH Tree" />
        <Anchor as={Link} to="/cthTable" label="Get CTH Table" />
        <Anchor as={Link} to="/namesearch" label="Instances" />
      </Nav>
    </>
  );
}
