import React from "react";

import nodeData from "../data/data_node.json";
import edgeData from "../data/data_edge.json";

import BoilerPlateGraph from "./BoilerPlateGraph";

function getNodesFromEdges(edges) {
  let nodes = [];
  edges.forEach((edge) => {
    nodes.push(edge.source, edge.target);
  });
  // remove duplicates of nodes
  nodes = [...new Set(nodes)];
  console.log(nodes);
  let nodesData = [];
  for (let i = 0; i < nodeData.length; i++) {
    if (nodes.includes(nodeData[i].Id)) {
      nodesData.push({
        id: nodeData[i].Id,
        label: nodeData[i].Id,
        group: nodeData[i].shape,
      });
    }
  }
  return nodesData;
}



function getTreeEdges(CTH, level) {
  // loop through the edges and find the nodes that are connected to the nodeId
  let nodes = [];
  let edges = [];
  for (let i = 0; i < edgeData.length; i++) {
    if (edgeData[i]["CTH#"] == CTH) {
      nodes.push(edgeData[i].source, edgeData[i].target);
      edges.push(edgeData[i]);
    }
  }

  // remove duplicates of nodes
  nodes = [...new Set(nodes)];
  if (level > 1) {
    for (let i = 0; i < nodes.length; i++) {
      let newEdges = getTreeEdges(nodes[i], level - 1);
      newEdges.forEach((edge) => {
        edges.push(edge);
      });
    }
  }
  return edges;
}

function getTreeNodes(nodeId, level = 1) {
  let edges = getTreeEdges(nodeId, level);
  let nodes = getNodesFromEdges(edges);
  console.table(nodes, edges);
  return { nodes: nodes, links: edges };
}

export default function NameTree() {
  return (
    <>
      <BoilerPlateGraph
        getDataFunction={getTreeNodes}
        title="Get CTH Tree"
        inputText="Enter Node Id"
      />
    </>
  );
}
