import React from "react";

import nodeData from "../data/data_node.json";
import edgeData from "../data/data_edge.json";

import BoilerPlateGraph from "./BoilerPlateGraph";

function getNeigbourNodes(nodeId) {
  let neighbourEdges = [];
  let neighbourNodes = [];
  let nodes = [];
  edgeData.forEach((edge) => {
    if (edge.source === nodeId || edge.target === nodeId) {
      neighbourEdges.push({
        source: edge.source,
        target: edge.target,
        tablet: edge.tablet,
      });
      if (edge.source === nodeId) {
        neighbourNodes.push(edge.target);
      }
      if (edge.target === nodeId) {
        neighbourNodes.push(edge.source);
      }
    }
  });
  edgeData.forEach((edge) => {
    if (
      neighbourNodes.includes(edge.source) &&
      neighbourNodes.includes(edge.target)
    ) {
      neighbourEdges.push({
        source: edge.source,
        target: edge.target,
        tablet: edge.tablet,
      });
    }
  });

  nodeData.forEach((node) => {
    if (neighbourNodes.includes(node.Id) || node.Id === nodeId) {
      nodes.push({ id: node.Id, label: node.Id, group: node.shape });
    }
  });

  return { nodes: nodes, links: neighbourEdges };
}

export default function NameAndNeighbour() {
  return (
    <>
      <BoilerPlateGraph
        getDataFunction={getNeigbourNodes}
        title="Neighbour Nodes"
        inputText="Enter Node Id"
      />
    </>
  );
}
