import React from "react";
import instances from "../data/instances.json";
import { DataTable, TextInput } from "grommet";

function makeTable(CTH) {
  // loop through instances and find the ones that are connected to the CTH#
  let instancesData = [];
  for (let i = 0; i < instances.length; i++) {
    if (instances[i]["CTH#"] == CTH) {
      instancesData.push(instances[i]);
    }
  }
  return instancesData;
}

export default function CTHTable() {
  const [CTH, setCTH] = React.useState("");
  return (
    <>
      <TextInput
        placeholder="Enter CTH"
        value={CTH}
        onChange={(event) => setCTH(event.target.value)}
      />
      <DataTable
        sortable
        paginate
        step={10}
        columns={[
          {
            property: "CTH#",
            header: "CTH#",
            primary: true,
          },
          {
            property: "tablet",
            header: "Publication",
          },
          {
            property: "Name",
            header: "Name",
          },
          {
            property: "stelle",
            header: "Stelle",
          },
        ]}
        data={makeTable(CTH)}
      />
    </>
  );
}
