// a text input field that will be used to search for a name
// normalize name by function normalizeName

import React from "react";
import { Box, Card, Anchor, TextInput, Text, List } from "grommet";
import instances from "../data/instances.json";
import cthGenre from "../data/cthGenre.json";

// cthGenre is a object where keys are Genre and values are subgenre
// subgenre is array where each element is a dic with keys: title and CTH

function normalizeName(name) {
  // lowercase and then uppercase first letter
  // replace s to š
  // replace h to ḫ

  name = name.toLowerCase();
  name = name.replace(/s/g, "š");
  name = name.replace(/h/g, "ḫ");
  name = name.charAt(0).toUpperCase() + name.slice(1);
  return name;
}

function getData(name) {
  // instances is array of dics
  // each dic has keys: name, cth, genre, instances
  let nameInstances = instances.filter((instance) => instance.Name === name);
  return nameInstances;
}

function getGenre(cth) {
  // convert cth to number

  cth = parseInt(cth);
  // cthGenre is object where keys are Genre and values are subgenre
  // subgenre is object where keys are subgenre names and values are array of dics with keys: title and CTH
  // for genres in cthGenre
  // for subgenres in genres
  // if subgenre.CTH === cth
  // return genres
  let genres = Object.keys(cthGenre);
  for (let i = 0; i < genres.length; i++) {
    let subgenres = Object.keys(cthGenre[genres[i]]);
    for (let j = 0; j < subgenres.length; j++) {
      let cths = cthGenre[genres[i]][subgenres[j]];
      for (let k = 0; k < cths.length; k++) {
        if (cth === cths[k].CTH) {
          return {
            genre: genres[i],
            subgenre: subgenres[j],
            title: cths[k].title,
          };
        }
      }
    }
  }
}

function getTabletOcurrunces(tablet) {
  let tabletInstances = instances.filter(
    (instance) => instance.tablet === tablet
  );
  // return Name of each instance
  let others = tabletInstances.map((instance) => instance.Name);
  let othersSet = new Set(others);
  // make it a string with commas
  let othersString = Array.from(othersSet).join(", ");
  return othersString;
}

function renderData(name) {
  let data = getData(name);
  let result = [];
  for (let i = 0; i < data.length; i++) {
    let genre = getGenre(data[i].CTH);
    result.push(
      <Card
        key={i}
        background="light-1"
        pad="small"
        margin="small"
        width="medium"
        height="small"
        direction="column"
        justify="between"
      >
        <Text>CTH: {data[i].CTH}</Text>
        <Text>Genre: {genre.genre}</Text>
        <Text>Subgenre: {genre.subgenre}</Text>
        <Text>Title: {genre.title}</Text>
        <Text>Co-occurrences: </Text>
        <Box>{getTabletOcurrunces(data[i].tablet)}</Box>
      </Card>
    );
  }

  return result;
}

export default function NameSearch() {
  const [name, setName] = React.useState("");

  return (
    <>
      <TextInput
        placeholder="Search by Name"
        value={name}
        onChange={(event) => {
          setName(normalizeName(event.target.value));
        }}
      />
      <Box direction="row" wrap={true}>
        {renderData(name)}
      </Box>
    </>
  );
}
