import React from "react";
import { TextInput, RadioButtonGroup, DataTable } from "grommet";
import Graph2D from "./Graph2D";
import Graph3D from "./Graph3D";

export default function BoilerPlateGraph({
  getDataFunction,
  title,
  inputText,
}) {
  const [graphData, setGraphData] = React.useState(0);
  const [dimension, setDimension] = React.useState("2D");

  // if dimension is 2D, then import Graph2D
  // if dimension is 3D, then import Graph3D
  const Graph = dimension === "2D" ? Graph2D : Graph3D;
  let data = getDataFunction(graphData);
  return (
    <>
      <h1>{title}</h1>
      <TextInput
        placeholder={inputText}
        onChange={(e) => {
          setGraphData(e.target.value);
        }}
      />

      <Graph graphData={data} />
    </>
  );
}
